import { Container } from '@bootstrap-styled/v4'
import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import theme from '../../utils/constants'
import Img from 'gatsby-image'

const S = {}

S.Container = styled(Container)`
  height: ${props => props.height ? `${props.height}px` : '100vh'};
  margin-top: -89px;

  background-size: cover;
  background-position: center;

  display: flex;

  position: relative;
`

S.Content = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  z-index: 2;
`

S.Img = styled(Img)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

S.Tint = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
`

const HeroImg = ({ children, image }) => {

  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(Math.max(window.innerHeight, document.documentElement.clientHeight));

    window.onresize = () => {
      setHeight(Math.max(window.innerHeight, document.documentElement.clientHeight));
    }
  })


  return (
    <S.Container
      className="px-0"
      fluid
      height={height}
    >
      <S.Content>
        {children}
      </S.Content>
      <S.Img fluid={image.childImageSharp.fluid} />
      <S.Tint />
    </S.Container>
  )
}

export default HeroImg
