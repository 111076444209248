import { Row, Col, H1, H5, P } from '@bootstrap-styled/v4'

import Container from '../Common/Container'
import React from 'react'
import styled from 'styled-components'
import theme from '../../utils/constants'
import Img from 'gatsby-image'

import ScrollAnimation from 'react-animate-on-scroll';


const S = {}

S.MobileImg = styled(Img)`
  margin-bottom: 2em;

  ${theme.breakpoints.lg} {
    display: none;
  }
`

S.DesktopImg = styled(Img)`
  display: none;

  ${theme.breakpoints.lg} {
    display: block;
  }
`

S.Heading = styled(H5)`
  color: ${theme.colors.lightGray};
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: ${theme.fontSizes[1]}px;
  letter-spacing: 1px;

  ${theme.breakpoints.sm} {
    margin-bottom: 1rem;
  }

  ${theme.breakpoints.md} {
    font-size: ${theme.fontSizes[2]}px;
  }

  ${theme.breakpoints.lg} {
    text-align: left;
    margin-bottom: 1.5rem;
    letter-spacing: 2px;
  }

  ${theme.breakpoints.xxl} {
    letter-spacing: 3px;
  }
`

S.Title = styled(H1)`
  font-family: ${theme.fonts.article};
  letter-spacing: 3px;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 1.25rem;
  text-align: center;
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes[4]}px;

  ${theme.breakpoints.sm} {
    margin-bottom: 2rem;
  }

  ${theme.breakpoints.md} {
    font-size: ${theme.fontSizes[6]}px;
  }

  ${theme.breakpoints.lg} {
    font-size: ${theme.fontSizes[5]}px;
    text-align: left;
  }

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[6]}px;
  }
`

S.Text = styled(P)`
  font-size: ${theme.fontSizes[2]}px;
  line-height: 1.5;
  color: ${theme.colors.lighterGray};
  text-align: center;

  ${theme.breakpoints.sm} {
    line-height: 2;
  }

  ${theme.breakpoints.md} {
    font-size: ${theme.fontSizes[3]}px;
  }

  ${theme.breakpoints.lg} {
    font-size: ${theme.fontSizes[2]}px;
    text-align: left;
  }

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[3]}px;
  }
`
S.Tint = styled.div`
  position: absolute;
  left: 0;
  top: 8rem;
  width: 100%;
  height: calc(100% - 8rem);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);

  ${theme.breakpoints.lg} {
    background-color: rgba(0, 0, 0, 0.4);
  }
`

S.Div = styled.div`
  position: absolute;
  display: flex;
  top: 8rem;
  left:0;
  width: 100%;
  height: calc(100% - 8rem);
`

S.Container = styled(Container)`
  position: relative;
`

S.Contentcontainer = styled(Container)`
  margin-top: auto;
  margin-bottom: auto;
  z-index: 2;
`

S.Row = styled(Row)`

`

const Professional = class extends React.Component {
  render() {
    const props = this.props;
    return (
      <ScrollAnimation animateIn="fadeInUp" animateOnce>
        <S.Container id="professional" className="px-0" fluid>
          <S.DesktopImg fluid={props.desktop.childImageSharp.fluid} />
          <S.MobileImg fluid={props.mobile.childImageSharp.fluid} />
          <S.Tint />
          <S.Div>
            <S.Contentcontainer className="py-0">
              <Row>
                <Col lg="8">
                  <S.Heading>Professional Profile</S.Heading>
                  <S.Title>Corporate Lifestyle Portraits & Business Profiles : A New Take </S.Title>
                  <S.Text>Now, professionals are seen to be bringing business to a more personal level,
                  and that means that having a strong, identifiable yet relatable image that reinforces
                  your corporate identity has become more important than ever. Injecting conventional
                  corporate portraits with our lifestyle approach to photography, we are all about
                  keeping things natural, relaxed and in the moment. </S.Text>
                </Col>
              </Row>
            </S.Contentcontainer>
          </S.Div>
        </S.Container>
      </ScrollAnimation>
    )
  }
}

export default Professional
