import { H1, H5, P } from '@bootstrap-styled/v4'
import React from 'react'

import styled from 'styled-components'

import Link from '../Common/Link'

import theme from '../../utils/constants'

const S = {}

S.Heading = styled(H5)`
  color: ${theme.colors.gray};
  text-align: center;
  margin-bottom: 1rem;
  font-size: ${theme.fontSizes[1]}px;
  letter-spacing: 1px;

  ${theme.breakpoints.md} {
    font-size: ${theme.fontSizes[2]}px;
  }

  ${theme.breakpoints.lg} {
    margin-bottom: 1.5rem;
    letter-spacing: 2px;
  }

  ${theme.breakpoints.xxl} {
    letter-spacing: 3px;
  }
`

S.Title = styled(H1)`
  font-family: ${theme.fonts.article};
  letter-spacing: 3px;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: center;

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[6]}px;
    margin-bottom: 2rem;
  }
`

S.Text = styled(P)`
  font-size: ${theme.fontSizes[2]}px;
  line-height: 2;
  color: ${theme.colors.gray};
  text-align: center;
  margin-bottom: 2rem;

  ${theme.breakpoints.md} {
    margin-bottom: 4rem;
  }

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[3]}px;
  }
`

const GraduationAbout = () => (
  <>
    <S.Heading>Graduation</S.Heading>
    <S.Title>The end of the begnnning of one's life</S.Title>
    <S.Text>Graduation marks a significant milestone in ones life. It is the transition of phases of life from receiving to giving. Therefore it is worth the effort to take some time to celebrate this moment by booking a graduation photoshoot. A professional set of photographs make the event that little bit more special, with you and your beloved family</S.Text>
  </>
)

export default GraduationAbout
