import { Row, Col } from '@bootstrap-styled/v4'
import React from 'react'

import styled from 'styled-components'

import Img from 'gatsby-image'

import Link from '../Common/Link'

import theme from '../../utils/constants'

const S = {}

S.Row = styled(Row)`
  margin-bottom: 2rem;

  ${theme.breakpoints.lg} {
    margin-bottom: unset;
  }
`

const WeddingGallery = ({ images }) => (
  <>
    <S.Row>
      {images.map(image => (
        <Col xs="4" className="px-0">
          <Img fluid={image.childImageSharp.fluid} />
        </Col>
      ))}
    </S.Row>
  </>
)

export default WeddingGallery
