import { H1, H5, P } from '@bootstrap-styled/v4'
import React from 'react'

import styled from 'styled-components'

import Link from '../Common/Link'

import theme from '../../utils/constants'

const S = {}

S.Heading = styled(H5)`
  color: ${theme.colors.gray};
  text-align: center;
  margin-bottom: 1rem;
  font-size: ${theme.fontSizes[1]}px;
  letter-spacing: 1px;

  ${theme.breakpoints.md} {
    font-size: ${theme.fontSizes[2]}px;
  }

  ${theme.breakpoints.lg} {
    margin-bottom: 1.5rem;
    letter-spacing: 2px;
  }

  ${theme.breakpoints.xxl} {
    letter-spacing: 3px;
  }
`

S.Title = styled(H1)`
  font-family: ${theme.fonts.article};
  letter-spacing: 3px;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: center;

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[6]}px;
    margin-bottom: 2rem;
  }
`

S.Text = styled(P)`
  font-size: ${theme.fontSizes[2]}px;
  line-height: 2;
  color: ${theme.colors.gray};
  text-align: center;
  margin-bottom: 2rem;

  ${theme.breakpoints.md} {
    margin-bottom: 4rem;
  }

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[3]}px;
  }
`

const WeddingAbout = () => (
  <>
    <S.Heading>Wedding</S.Heading>
    <S.Title>Love is composed of a single soul inhabiting two bodies</S.Title>
    <S.Text>Billions of people in the world and all these winding roads have lead you to this one person. Everyone’s got a story to tell, and we’d love to hear yours. With an emphasis in fine art portraiture using both digital and film for that extra sentimental warmth, we curate photographs and memories revolving around your love story. A quintessential experience perfect in the run up to your wedding, celebrating anything from milestones to anniversaries.</S.Text>
  </>
)

export default WeddingAbout
