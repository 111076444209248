import Container from '../Common/Container'
import React from 'react'

import styled from 'styled-components'

import ScrollAnimation from 'react-animate-on-scroll';
import theme from '../../utils/constants';

import GraduationAbout from '../Block/GraduationAbout'
import Gallery from '../Block/Gallery'

const S = {}

S.Container = styled(Container)`
  display: flex;
  flex-direction: column-reverse;

  ${theme.breakpoints.lg} {
    flex-direction: column;
  }
`

S.Div = styled.div`
  width: 100%;
`

const Graduation = ({ gallery }) => (
  <S.Container id="graduation">
    <ScrollAnimation animateIn="fadeInUp" animateOnce>
      <S.Div>
        <GraduationAbout />
      </S.Div>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeInUp" animateOnce>
      <S.Div>
        <Gallery images={gallery} />
      </S.Div>
    </ScrollAnimation>
  </S.Container>
)

export default Graduation
