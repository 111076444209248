import Helmet from 'react-helmet'
import Layout from '../components/Section/Layout'
import Hero from '../components/Block/Hero'
import About from '../components/Section/About'
import Wedding from '../components/Section/Wedding'
import Graduation from '../components/Section/Graduation'
import Professional from '../components/Block/Professional'
import React from 'react'
import { graphql } from 'gatsby'

class IndexPage extends React.Component {
  constructor() {
    super()
  }

  render() {
    const data = this.props.data;
    return (
      <Layout>
        <Helmet>
          <title>Hourglass Studio | Top KL Malaysia Photography Studio</title>
        </Helmet>
        <Hero image={data.hero} />
        <About mobile={data.aboutMobile} desktop={data.aboutDesktop}/>
        <Wedding gallery={[
          data.wedding1,
          data.wedding2,
          data.wedding3,
          data.wedding4,
          data.wedding5,
          data.wedding6,
          data.wedding7,
          data.wedding8,
          data.wedding9
        ]}/>
        <Professional mobile={data.professionalMobile} desktop={data.professionalDesktop}/>
        <Graduation gallery={[
          data.graduation1,
          data.graduation2,
          data.graduation3,
          data.graduation4,
          data.graduation5,
          data.graduation6
        ]} />
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 86) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aboutMobile: file(relativePath: { eq: "about-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    aboutDesktop: file(relativePath: { eq: "about-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 585, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    wedding1: file(relativePath: { eq: "wedding1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    wedding2: file(relativePath: { eq: "wedding2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    wedding3: file(relativePath: { eq: "wedding3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    wedding4: file(relativePath: { eq: "wedding4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    wedding5: file(relativePath: { eq: "wedding5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    wedding6: file(relativePath: { eq: "wedding6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    wedding7: file(relativePath: { eq: "wedding7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    wedding8: file(relativePath: { eq: "wedding8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    wedding9: file(relativePath: { eq: "wedding9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    professionalDesktop: file(relativePath: { eq: "professionalDesktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    professionalMobile: file(relativePath: { eq: "professionalMobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    graduation1: file(relativePath: { eq: "graduation1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    graduation2: file(relativePath: { eq: "graduation2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    graduation3: file(relativePath: { eq: "graduation3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    graduation4: file(relativePath: { eq: "graduation4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    graduation5: file(relativePath: { eq: "graduation5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    graduation6: file(relativePath: { eq: "graduation6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 86) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
