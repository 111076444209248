import { Row, Col, H1, H5, P } from '@bootstrap-styled/v4'

import Container from '../Common/Container'
import React from 'react'
import styled from 'styled-components'
import theme from '../../utils/constants'
import Img from 'gatsby-image'

import ScrollAnimation from 'react-animate-on-scroll';

const S = {}

S.MobileImg = styled(Img)`
  margin-bottom: 2em;

  ${theme.breakpoints.lg} {
    display: none;
  }
`

S.DesktopImg = styled(Img)`
  display: none;

  ${theme.breakpoints.lg} {
    display: block;
  }
`

S.RightCol = styled(Col)`
  margin-bottom: auto;
  margin-top: 2rem;

  ${theme.breakpoints.lg} {
    margin-top: auto;
  }

  && {

    ${theme.breakpoints.lg} {
      padding-left: 2rem;
    }
  }
`

S.Heading = styled(H5)`
  color: ${theme.colors.gray};
  text-align: center;
  margin-bottom: 1rem;
  font-size: ${theme.fontSizes[1]}px;
  letter-spacing: 1px;

  ${theme.breakpoints.md} {
    font-size: ${theme.fontSizes[2]}px;
  }

  ${theme.breakpoints.lg} {
    text-align: left;
    margin-bottom: 1.5rem;
    letter-spacing: 2px;
  }

  ${theme.breakpoints.xxl} {
    letter-spacing: 3px;
  }
`

S.Title = styled(H1)`
  font-family: ${theme.fonts.article};
  letter-spacing: 3px;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: center;

  ${theme.breakpoints.lg} {
    text-align: left;
  }

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[6]}px;
    margin-bottom: 2rem;
  }
`

S.Text = styled(P)`
  font-size: ${theme.fontSizes[2]}px;
  line-height: 2;
  color: ${theme.colors.gray};
  text-align: center;

  ${theme.breakpoints.lg} {
    text-align: left;
  }

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[3]}px;
  }
`

const About = ({ mobile, desktop }) => {
  return (
    <Container id="about">
      <Row>
        <Col lg="6">
          <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <S.MobileImg fluid={mobile.childImageSharp.fluid} />
            <S.DesktopImg fluid={desktop.childImageSharp.fluid} />
          </ScrollAnimation>
        </Col>
        <S.RightCol lg="6">
          <ScrollAnimation animateIn="fadeInRight" delay="300" animateOnce>
            <S.Heading>About Hourglass Studio</S.Heading>
            <S.Title>We believe in every bit of memories worth recording.</S.Title>
            <S.Text>Time flows. Memory fades. Capturing the unique and emotional moments of people we work with had been our mission since we first started photography. We believe that the true values of the photography equipment lie in the memories that it created.</S.Text>
          </ScrollAnimation>
        </S.RightCol>
      </Row>
    </Container>
  )
}

export default About
