import { H1, P, Button } from '@bootstrap-styled/v4'

import Link from '../Common/Link'
import HeroImg from '../Common/HeroImg'
import React from 'react'
import styled from 'styled-components'
import theme from '../../utils/constants'

const S = {}

S.Title = styled(H1)`
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeights.sans.normal};
  text-align: center;
  font-size: ${theme.fontSizes[5]}px;
  line-height: 1.5;
  letter-spacing: 0.25rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;

  padding-left: 1rem;
  padding-right: 1rem;
  && {
  }

  ${theme.breakpoints.lg} {
    letter-spacing: 0.5rem;
    width: 90vw;
  }

  ${theme.breakpoints.xxl} {
    font-size: ${theme.fontSizes[5]}px;
    letter-spacing: 0.75rem;
    margin-bottom: 1.5rem;
  }
`

S.Button = styled(Button)`
  && {
    color: ${theme.colors.white};
    border-color: ${theme.colors.white};
  }

  &&:hover {
    color: ${theme.colors.darkGray};
    background-color: ${theme.colors.white};
  }
`

S.Link = styled(Link)`
  margin-left: auto;
  margin-right: auto;
`

S.ButtonText = styled(P)`
  margin-bottom: 0px;
`


const Hero = class extends React.Component {
  render() {
    const props = this.props;
    return (
      <HeroImg image={props.image}>
        <S.Title>Freezing memories with photography</S.Title>
        <S.Link to="/contact"><S.Button outline size="md"><S.ButtonText>Get in touch</S.ButtonText></S.Button></S.Link>
      </HeroImg>
    )
  }
}

export default Hero
